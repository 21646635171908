const config = {};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`TokenAdminDataProvider.init: config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getSPEToken: async (body) => {
        let url = `${config.baseURL}/admin/token?format=json`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "action": "get",
                "type": "spe",
                "userId": body.userId,
                "path": body.path,
                "profile": body.profile,
                "ttl": body.ttl
            })
        })
            .then(payload => {
                return payload
            })
            .catch(error => {
                return {error}
            })
    },

    getISPToken: async (body) => {
        let url = `${config.baseURL}/admin/token?format=json`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "action": "get",
                "type": "isp",
                "userId": body.userId,
                "assetId": body.assetId,
                "mediaId": body.mediaId,
                "customDrmPolicy": JSON.stringify(body.customDrmPolicy),
                "customDrmPolicyString": body.customDrmPolicyString,
                "deviceId": body.deviceId,
                "drmPolicy": body.drmPolicy,
                "ttl": body.ttl
            })
        })
            .then(payload => {
                return payload
            })
            .catch(error => {
                return {error}
            })
    },

    getNGToken: async (body) => {
        let url = `${config.baseURL}/admin/token?format=json`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "action": "get",
                "type": "ngtv",
                "userId": body.userId,
                "mvpd": body.mvpd,
                "ttl": body.ttl
            })
        })
            .then(payload => {
                return payload
            })
            .catch(error => {
                return {error}
            })
    },

    getTurnerToken: async body => {
        let url = `${config.baseURL}/admin/token?format=json`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "action": "get",
                "type": "turner",
                "requestorId": body.requestorId,
                "resourceId": body.resourceId,
                "sanction": body.sanction,
                "userId": body.userId,
                "concurrencyLimit": body.concurrencyLimit,
                "concurrencySilo": body.concurrencySilo,
                "mediaId": body.mediaId,
                "mvpd": body.mvpd,
                "path": body.path,
                "renewal": body.renewal,
                "requestId": body.requestId,
                "entitledNetworks": body.entitledNetworks,
                "entitlements": body.entitlements,
                "products": body.products,
                "ttl": body.ttl
            })
        })
            .then(payload => {
                return payload
            })
            .catch(error => {
                return {error}
            })
    },

    renewToken: async (body) => {
        let url = `${config.baseURL}/admin/token?format=json`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "action": "renew",
                "type": body.type,
                "token": body.token,   // Required. Token String.
                "userId": body.userId,
                "ttl": body.ttl
            })
        })
            .then(payload => {
                return payload
            })
            .catch(error => {
                return {error}
            })
    },

    viewISPToken: async (body) => {
        let url = `${config.baseURL}/admin/token?format=json`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "action": "view",
                "type": "isp",
                "userId": body.userId,                     // Required. User that initiated this request.
                "token": body.token                        // Required. Encrypted ISP token string.
            })
        })
        .then(payload => {
            return payload
        })
        .catch(error => {
            return {error}
        })
    },


    getAssets: async (body) => {
        let url = `${config.baseURL}/admin/asset`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
            .then(payload => {
                return payload
            })
            .catch(error => {
                return {error}
            });
    }
}
