const axios = require("axios");
const config = {};

axios.defaults.withCredentials = true;

module.exports = {
    init: baseURL => {
        config.baseURL = baseURL;
        return config.baseURL && true;
    },

    setMetadata: async (key, value) => {
        return await axios.post(`${config.baseURL}/admin/metadata`, {
            action: "set",
            key,
            value
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getMetadata: async () => {
        return await axios.post(`${config.baseURL}/admin/metadata`, {
            action: "get"
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};
