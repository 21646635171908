const axios = require("axios");
axios.defaults.withCredentials = true;

let config = {};

module.exports = {
    init: baseURL => {
        config.baseURL = baseURL;

        return config.baseURL && true;
    },

    getByTitleId: (titleId, instance) => {
        return axios.get(`${config.baseURL}/show/title/${titleId}`, {
            withCredentials: false,
            params: {
                instance
            }
        }).then(response => {
            console.log("(TimeMachineDataProvider.getByTitleId) response data: ", response.data);
            return response.data;
        }).catch(error => {
            console.error("(TimeMachineDataProvider.getByTitleId) error: ", error);
            return {error};
        })
    },

    getCurrentShow: instance => {
        return axios.get(`${config.baseURL}/currentShow`, {
            params: {
                instance
            },
            withCredentials: false
        }).then(response => {
            console.log("(TimeMachineDataProvider.getCurrentShow) response data: ", response.data);
            return response.data;
        }).catch(error => {
            console.error("(TimeMachineDataProvider.getCurrentShow) error: ", error);
            return {error};
        })
    },

    getAudit: async (feedIds, ingesters, statuses, actions, result_limit, payloadTypes, propEps, titleIds, externalIds, showIds, start, end, matchAnyInstances, matchAnyIngesters, matchAnyStatuses, matchAnyPayloadTypes, matchAnyPropEps, matchAnyShowIds, matchAnyExternalIds, matchAnyTitleIds, startIndex=0) => {
        const criteria = {
            ...actions.length > 0 && {
                action: {
                    matchesAny: true,
                    values: [actions]
                }
            },
            ...result_limit.length > 0 && {
                result_limit: {
                    matchesAny: true,
                    values: result_limit
                }
            },
            ...payloadTypes.length > 0 && {
                "payloads.type": {
                    matchesAny: matchAnyPayloadTypes,
                    values: payloadTypes
                }
            },
            ...titleIds.length > 0 && {
                title_id: {
                    matchesAny: matchAnyTitleIds,
                    values: titleIds
                }
            },
            ...externalIds.length > 0 && {
                external_id: {
                    matchesAny: matchAnyExternalIds,
                    values: externalIds
                }
            },
            ...showIds.length > 0 && {
                show_id: {
                    matchesAny: matchAnyShowIds,
                    values: showIds
                }
            },
            ...feedIds.length > 0 && {
                feed_id: {
                    matchesAny: matchAnyInstances,
                    values: feedIds
                }
            },
            ...ingesters.length > 0 && {
                ingester: {
                    matchesAny: matchAnyIngesters,
                    values: ingesters
                }
            },
            ...statuses.length > 0 && {
                status: {
                    matchesAny: matchAnyStatuses,
                    values: statuses
                }
            },
            ...propEps.length > 0 && {
                propEps: {
                    matchesAny: matchAnyPropEps,
                    values: propEps
                }
            },
            ...start !== null && {start: parseInt(start / 1000)},
            ...end !== null && {end: parseInt(end / 1000)},
            ...result_limit !== null && {result_limit: parseInt(result_limit)},
            from: startIndex
        };

        console.log("TimeMachineDataProvider.getAudit: criteria: ", criteria);
        return axios.post(`${config.baseURL}/admin/audit`, {
            action: "GET",
            criteria
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            console.error("(TimeMachineDataProvider.getAudit) error: ", error);
            return {error};
        });
    },

    replay: (instance, ingesters, external_id, show_id, title_id, payloadTypes, prop_eps, statuses, start, end, matchAnyInstances, matchAnyIngesters, matchAnyPayloadTypes, matchAnyShowIds, matchAnyExternalIds, matchAnyTitleIds, matchAnyPropEps, matchAnyStatuses) => {
        return axios.post(`${config.baseURL}/admin/replay`, {
            ...instance.length > 0 && {
                instance: {
                    matchesAny: matchAnyInstances,
                    values: instance
                }
            },
            ...ingesters.length > 0 && {
                ingester: {
                    matchesAny: matchAnyIngesters,
                    values: ingesters
                }
            },
            ...external_id.length > 0 && {
                external_id: {
                    matchesAny: matchAnyExternalIds,
                    values: external_id
                }
            },
            ...show_id.length > 0 && {
                show_id: {
                    matchesAny: matchAnyShowIds,
                    values: show_id
                }
            },
            ...title_id.length > 0 && {
                title_id: {
                    matchesAny: matchAnyTitleIds,
                    values: title_id
                }
            },
            ...payloadTypes.length > 0 && {
                "payloads.type": {
                    matchesAny: matchAnyPayloadTypes,
                    values: payloadTypes
                }
            },
            ...statuses.length > 0 && {
                status: {
                    matchesAny: matchAnyStatuses,
                    values: statuses
                }
            },
            ...prop_eps.length > 0 && {
                prop_ep: {
                    matchesAny: matchAnyPropEps,
                    values: prop_eps
                }
            },
            ...start !== null && {start: parseInt(start / 1000)},
            ...end !== null && {end: parseInt(end / 1000)},
            audit: true
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            console.error("(TimeMachineDataProvider.getAudit) error: ", error);
            return {error};
        });
    },

    replayShow: async (start, end, time_attr, instances, showId, externalId, titleId, propEp, paid, titleName, programHash, seasonId, seasonHash, seasonName, seriesId, seriesHash, seriesName, keyword, matchAnyInstances, matchAnyShowIds, matchAnyExternalIds, matchAnyTitleIds, matchAnyPropEps, matchAnyPaids, matchAnyTitleNames, matchAnyProgramHashes, matchAnySeasonIds, matchAnySeasonHashes, matchAnySeasonNames, matchAnySeriesIds, matchAnySeriesHashes, matchAnySeriesNames, matchAnyKeywords, update_titles) => {
        return await axios.post(`${config.baseURL}/admin/show/replay`, {
            update_titles,
            ...instances.length > 0 && {
                instance: {
                    values: instances,
                    matchesAny: matchAnyInstances
                }
            },
            ...showId.length > 0 && {
                show_id: {
                    values: showId,
                    matchesAny: matchAnyShowIds
                }
            },
            ...externalId.length > 0 && {
                external_id: {
                    values: externalId,
                    matchesAny: matchAnyExternalIds
                }
            },
            ...titleId.length > 0 && {
                title_id: {
                    values: titleId,
                    matchesAny: matchAnyTitleIds
                }
            },
            ...propEp.length > 0 && {
                prop_ep: {
                    values: propEp,
                    matchesAny: matchAnyPropEps,
                }
            },
            ...paid.length > 0 && {
                paid: {
                    values: paid,
                    matchesAny: matchAnyPaids,
                }
            },
            ...titleName.length > 0 && {
                title_name: {
                    values: titleName,
                    matchesAny: matchAnyTitleNames
                }
            },
            ...programHash.length > 0 && {
                program_hash: {
                    values: programHash,
                    matchesAny: matchAnyProgramHashes
                }
            },
            ...seasonId.length > 0 && {
                season_id: {
                    values: seasonId,
                    matchesAny: matchAnySeasonIds
                }
            },
            ...seasonHash.length > 0 && {
                season_hash: {
                    values: seasonHash,
                    matchesAny: matchAnySeasonHashes
                }
            },
            ...seasonName.length > 0 && {
                season_name: {
                    values: seasonName,
                    matchesAny: matchAnySeasonNames
                }
            },
            ...seriesId.length > 0 && {
                series_id: {
                    values: seriesId,
                    matchesAny: matchAnySeriesIds
                }
            },
            ...seriesHash.length > 0 && {
                series_hash: {
                    values: seriesHash,
                    matchesAny: matchAnySeriesHashes
                }
            },
            ...seriesName.length > 0 && {
                series_name: {
                    values: seriesName,
                    matchesAny: matchAnySeriesNames
                }
            },
            ...keyword.length > 0 && {
                keywords: {
                    values: keyword,
                    matchesAny: matchAnyKeywords
                }
            },
            start,
            end,
            time_attr
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    getInstances: () => {
        return axios.post(`${config.baseURL}/admin/list/instances`).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getAllMetadata: () => {
        return axios.post(`${config.baseURL}/admin/metadata/retrieve`).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    setMetadata: (instance, metadata) => {
        return axios.post(`${config.baseURL}/admin/metadata`, {
            instance,
            metadata
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getWhitelistedMetadata: () => {
        return axios.post(`${config.baseURL}/admin/metadata/whitelisted`).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    searchShows: (startTime, endTime, timeAttr, instances, showId, externalId, titleId, propEp, paid, titleName, programHash, seasonId, seasonHash, seasonName, seriesId, seriesHash, seriesName, keyword, matchAnyInstances, matchAnyShowIds, matchAnyExternalIds, matchAnyTitleIds, matchAnyPropEps, matchAnyPaids, matchAnyTitleNames, matchAnyProgramHashes, matchAnySeasonIds, matchAnySeasonHashes, matchAnySeasonNames, matchAnySeriesIds, matchAnySeriesHashes, matchAnySeriesNames, matchAnyKeywords) => {
        return axios.post(`${config.baseURL}/admin/show/search`, {
            time_attr: timeAttr,
            start: parseInt(startTime.getTime() / 1000),
            end: parseInt(endTime.getTime() / 1000),
            ...instances.length > 0 && {
                instance: {
                    values: instances,
                    matchesAny: matchAnyInstances
                },
            },
            ...showId.length > 0 && {
                show_id: {
                    values: showId,
                    matchesAny: matchAnyShowIds
                }
            },
            ...externalId.length > 0 && {
                external_id: {
                    values: externalId,
                    matchesAny: matchAnyExternalIds
                }
            },
            ...titleId.length > 0 && {
                title_id: {
                    values: titleId,
                    matchesAny: matchAnyTitleIds
                }
            },
            ...propEp.length > 0 && {
                prop_ep: {
                    values: propEp,
                    matchesAny: matchAnyPropEps
                }
            },
            ...paid.length > 0 && {
                paid: {
                    values: paid,
                    matchesAny: matchAnyPaids
                }
            },
            ...titleName.length > 0 && {
                title_name: {
                    values: titleName,
                    matchesAny: matchAnyTitleNames
                }
            },
            ...programHash.length > 0 && {
                program_hash: {
                    values: programHash,
                    matchesAny: matchAnyProgramHashes
                }
            },
            ...seasonId.length > 0 && {
                season_id: {
                    values: seasonId,
                    matchesAny: matchAnySeasonIds
                }
            },
            ...seasonHash.length > 0 && {
                season_hash: {
                    values: seasonHash,
                    matchesAny: matchAnySeasonHashes
                }
            },
            ...seasonName.length > 0 && {
                season_name: {
                    values: seasonName,
                    matchesAny: matchAnySeasonNames
                }
            },
            ...seriesId.length > 0 && {
                series_id: {
                    values: seriesId,
                    matchesAny: matchAnySeriesIds
                }
            },
            ...seriesHash.length > 0 && {
                series_id: {
                    values: seriesHash,
                    matchesAny: matchAnySeriesHashes
                }
            },
            ...seriesName.length > 0 && {
                series_name: {
                    values: seriesName,
                    matchesAny: matchAnySeriesNames
                }
            },
            ...keyword.length > 0 && {
                keywords: {
                    values: keyword,
                    matchesAny: matchAnyKeywords
                }
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    cmsGetShows: (instance, franchise_id, series_id, season_id, prop_ep, title_id, external_id, show_id, start, end) => {
        return axios.post(`${config.baseURL}/admin/cms/shows`, {
            ...instance && {feed_id: instance},
            ...franchise_id && {franchise_id},
            ...series_id && {series_id},
            ...season_id && {season_id},
            ...title_id && {title_id},
            ...prop_ep && {prop_ep},
            ...show_id && {show_id},
            ...external_id && {external_id},
            ...start !== null && {start: parseInt((start / 1000).toString())},
            ...end !== null && {end: parseInt((end / 1000).toString())}
        }, {headers: {"Content-Type": "application/json"}}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    cmsGetOverrides: async (show_id, external_id, title_id, prop_ep, season_id, series_id, franchise_id, start_time, end_time) => {
        return await axios.post(`${config.baseURL}/admin/cms/override/get`, {
            ...show_id && {show_id},
            ...external_id && {external_id},
            ...title_id && {title_id},
            ...prop_ep && {prop_ep},
            ...season_id && {season_id},
            ...series_id && {series_id},
            ...franchise_id && {franchise_id},
            ...start_time && {startTime: parseInt((start_time.getTime() / 1000).toString())},
            ...end_time && {endTime: parseInt((end_time.getTime() / 1000).toString())}
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    cmsUpdateOverrides: overrides => {
        return axios.post(`${config.baseURL}/admin/cms/override/set`, {
            overrides
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    cmsGetImages: (franchise_id, series_id, season_id, prop_ep, title_id, external_id, show_id, start_time, end_time) => {
        return axios.post(`${config.baseURL}/admin/cms/override/images/get`, {
            ...prop_ep && {prop_ep},
            ...external_id && {external_id},
            ...show_id && {show_id},
            ...title_id && {title_id},
            ...season_id && {season_id},
            ...series_id && {series_id},
            ...franchise_id && {franchise_id},
            ...start_time && {startTime: parseInt((start_time.getTime() / 1000).toString())},
            ...end_time && {endTime: parseInt((end_time.getTime() / 1000).toString())}
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    cmsUploadImage: (image_id, scope, scope_id, file_format, imageB64) => {
        return axios.post(
            `${config.baseURL}/admin/cms/image/upload`, {
                image_id,
                ...scope === "Franchise" && {franchise_id: scope_id},
                ...scope === "Series" && {series_id: scope_id},
                ...scope === "Title by Prop Ep" && {prop_ep: scope_id},
                ...scope === "Title by Title Id" && {title_id: scope_id},
                ...scope === "Show by External Id" && {external_id: scope_id},
                ...scope === "Show by Show Id" && {show_id: scope_id},
                format: file_format,
                contents: imageB64
            }
        ).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    cmsAddImageToOverrides: (overrides) => {
        return axios.post(`${config.baseURL}/admin/cms/override/images/set`, {
            overrides
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    cmsDeleteImage: url => {
        return axios.post(`${config.baseURL}/admin/cms/image/delete`, {
            url
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    cmsAudit: (show_id, external_id, title_id, prop_ep, season_id, series_id, franchise_id, editor, category, start, end) => {
        return axios.post(`${config.baseURL}/admin/cms/override/audit`, {
            ...show_id.length > 0 && {show_id},
            ...external_id.length > 0 && {external_id},
            ...title_id.length > 0 && {title_id},
            ...prop_ep.length > 0 && {prop_ep},
            ...season_id.length > 0 && {season_id},
            ...series_id.length > 0 && {series_id},
            ...franchise_id.length > 0 && {franchise_id},
            ...editor.length > 0 && {editor},
            ...category.length > 0 && {category},
            ...start != null && {start: parseInt((start / 1000).toString())},
            ...end !== null && {end: parseInt((end / 1000).toString())}
        }).then(response => {
            return response.data;
        });
    },

    defaultUploadImage: (imageId, fileFormat, imageB64) => {
        return axios.post(
            `${config.baseURL}/admin/cms/image/upload`, {
                format: fileFormat,
                contents: imageB64
            }
        ).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    cmsSaveDefaultImages: default_images => {
        return axios.post(`${config.baseURL}/admin/cms/defaults/images/set`, {
            default_images
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getTaxonomies: () => {
        return axios.post(`${config.baseURL}/admin/taxonomy/get`).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
}
