const config = {};
module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getAllMetadata: async () => {
        let url = `${config.baseURL}/admin/metadata/retrieve`;

        return fetch(url, {
            method:'POST',
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },

    getAllInstances: async () => {
        let url = `${config.baseURL}/admin/list/instances`;

        return fetch(url, {
            method:'POST',
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },
    setMetadata: async (instance, metadata) => {
        let url = `${config.baseURL}/admin/metadata`;
        var data = {
            "instance": instance || "",
            "metadata": metadata || {}
        }


        return fetch(url, {
            method:'POST',
            body: JSON.stringify(
                data
            ),
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },

    deleteMetadata: async (instance) => {
        let url = `${config.baseURL}/admin/metadata/delete`;
        var data = {
            "instance": instance || ""
        }


        return fetch(url, {
            method:'POST',
            body: JSON.stringify(
                data
            ),
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },
    deleteFeed: async (instance) => {
        let url = `${config.baseURL}/admin/internal/purge`;
        var data = {
            "instance": instance || ""
        }

        return fetch(url, {
            method:'POST',
            headers:{
                "app-id": process.env.SYNAPSE_APP_ID,
            },
            body: JSON.stringify(
                data
            ),
            credentials: 'include',


        })
            .then(payload => {
                return payload;
            })
    }

}
