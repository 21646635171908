const config = {};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getMetadata: async () => {
        let url = `${config.baseURL}/admin/metadata?format=json`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "action": "get",
                "list": []
            })
        })
        .then(payload => {
            return payload
        })
        .catch(error => {
            return {error}
        })
    },

    setMetadata: async (metadata) => {
        let url = `${config.baseURL}/admin/metadata?format=json`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "action": "set",
                "map": metadata
            })
        })
        .then(payload => {
            return payload
        })
        .catch(error => {
            return {error}
        })
    }
}
