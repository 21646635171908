const axios = require("axios");
const config = {};

module.exports = {
    init: baseURL => {
        config.baseURL = baseURL;
        return config.baseURL && true;
    },

    getPlatforms: async () => {
        return await axios.get(`${config.baseURL}/admin/getPlatforms`, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    getBrands: async () => {
        return await axios.get(`${config.baseURL}/admin/getBrands`, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    getProviders: async () => {
        return await axios.get(`${config.baseURL}/admin/getProviders`, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getProviderById: async provider => {
        return await axios.get(`${config.baseURL}/admin/getConfig/${provider}`, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getCobrandDataByProvider: async provider => {
        return await axios.get(`${config.baseURL}/getCobrandData/${provider}`, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    enableCobrandData: async provider => {
        return await axios.post(`${config.baseURL}/admin/enableCobrandData/${provider}`, {}, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    disableCobrandData: async provider => {
        return await axios.post(`${config.baseURL}/admin/disableCobrandData/${provider}`, {}, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    setDarkphase: async (provider, brand, lines, phoneType, phoneText, linkType, linkText, linkUrl) => {
        return await axios.post(`${config.baseURL}/admin/provider/setDarkphase/${provider}/for/${brand}`, {
            darkphase: {
                lines,
                meta: {
                    phone1: {
                        type: phoneType,
                        text: phoneText
                    },
                    link1: {
                        type: linkType,
                        text: linkText,
                        url: linkUrl
                    }
                }
            }
        }, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    removeDarkphase: async (provider, brand) => {
        return await axios.post(`${config.baseURL}/admin/provider/removeDarkphase/${provider}/for/${brand}`, {}, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    setPrimaryOrder: async (provider, primaryOrder, brand, toggle) => {
        return await axios.post(`${config.baseURL}/admin/setPrimaryOrder/${provider}/${primaryOrder}`, {
            brands: {
                [brand]: {
                    toggle,
                    primaryOrder
                }
            }
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    removePrimaryOrder: async (provider, brand, toggle) => {
        return await axios.post(`${config.baseURL}/admin/removePrimaryOrder/${provider}`, {
            brands: {
                [brand]: {
                    toggle
                }
            }
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getStagedChanges: async brand => {
        return await axios.get(`${config.baseURL}/admin/getStagedChanges/${brand}`, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    compareConfigs: async (brand, version) => {
        return await axios.get(`${config.baseURL}/admin/compareConfigs/${brand}/${version}`, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    getConfig: async (brand, version) => {
        return await axios.get(`${config.baseURL}/v2/getConfig`, {
            params: {
                brand, version
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getLatestConfigData: async brand => {
        return await axios.get(`${config.baseURL}/admin/getLatestConfigData/${brand}`, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    createNewConfig: async (brand, releaseNotes) => {
        return await axios.post(`${config.baseURL}/superadmin/createNewConfig/${brand}`,
            {
                releaseNotes
            }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getDegradationStatus: async (authType, brand) => {
        return await axios.post(`${config.baseURL}/admin/degrade/view/${authType}/${brand}`, null, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
    },

    activateDegradation: async (authType, property, resource, provider, ttl) => {
        let url = `${config.baseURL}/admin/degrade/activate/${authType}/${property}/${provider}`;
        if (resource) {
            url += `/${resource}`;
        }
        return await axios.post(url, {
            ttl
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    deactivateDegradation: async (authType, property, provider, resource) => {
        let url = `${config.baseURL}/admin/degrade/deactivate/${authType}/${property}/${provider}`;
        if (resource) {
            url += `/${resource}`;
        }

        return await axios.post(url, null, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    addBrand: async (brand, provider) => {
        return await axios.post(`${config.baseURL}/admin/addBrand/${brand}/to/${provider}`, null, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    removeBrand: async (brand, provider) => {
        return await axios.post(`${config.baseURL}/admin/removeBrand/${brand}/from/${provider}`, null, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    updateProvider: async (brand, provider, platforms) => {
        return await axios.post(`${config.baseURL}/admin/config/updatePlatforms/${provider}/for/${brand}`, {
            platforms
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    publishConfig: async (brand, version) => {
        return await axios.post(`${config.baseURL}/superadmin/publishConfig/${brand}/${version}`, null, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getPrimaryOrder: async (brand=null) => {
        return await axios.post(`${config.baseURL}/admin/getPrimaryOrder${brand ? `/${brand}` : ""}`, null, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    createProvider: async (providerId, displayName, country, group, visible) => {
        return await axios.post(`${config.baseURL}/admin/createProvider`, {
            id: providerId,
            displayName,
            country,
            group,
            visible
        }, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    updateProviderBasicInfo: async (providerId, displayName, country, visibility, group, primaryOrder={}) => {
        return await axios.post(`${config.baseURL}/admin/update/provider/${providerId}`, {
            displayName,
            country,
            visibility,
            group,
            primaryOrder
        }, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    updateCallbackUrls: async (providerId, brandId, callbackUrls) => {
        return await axios.post(`${config.baseURL}/admin/provider/updateCallbackUrls/${providerId}/${brandId}`, {
            callbackUrls
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    createBrand: async (brandId, programmerId, displayName, cobrandingImgTemplate, primaryImgTemplate, defaultPlatforms=[]) => {
        return axios.post(`${config.baseURL}/admin/createBrand`, {
            id: brandId,
            brand: programmerId,
            displayName,
            cobrandingImgTemplate,
            primaryImgTemplate,
            defaultPlatforms
        }, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    createPlatform: async (platformId, displayName) => {
        return await axios.post(`${config.baseURL}/admin/createPlatform`, {
            id: platformId,
            displayName
        }, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    saveBrand: async (brandId, programmerId, displayName, cobrandingImgTemplate, primaryImgTemplate, defaultPlatforms) => {
        return await axios.post(`${config.baseURL}/admin/update/brand/${brandId}`, {
            displayName,
            brand: programmerId,
            primaryImgTemplate,
            cobrandImgTemplate: cobrandingImgTemplate,
            defaultPlatforms
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    savePlatform: async (platformId, displayName) => {
        return await axios.post(`${config.baseURL}/admin/platform/updateDisplayName/${platformId}`, {
            displayName
        }, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getGroups: async () => {
        return await axios.post(`${config.baseURL}/admin/getGroups`, null, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    addBrandToGroup: async (brand, group) => {
        return await axios.post(`${config.baseURL}/admin/addBrandToGroup/${brand}/${group}`, null, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    addProviderToGroup: async (provider, groupId) => {
        return await axios.post(`${config.baseURL}/admin/addProviderToGroup/${groupId}`, {
            id: provider
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    removeProviderFromGroup: async (provider, groupId) => {
        return await axios.post(`${config.baseURL}/admin/removeProviderFromGroup/${groupId}`, {
            id: provider
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    createGroup: async (groupId, displayName) => {
        return await axios.post(`${config.baseURL}/admin/addGroup`, {
            id: groupId,
            displayName
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    deleteGroup: async groupId => {
        return await axios.post(`${config.baseURL}/admin/removeGroup`, {
            id: groupId
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getProvidersByGroup: async groupId => {
        return await axios.post(`${config.baseURL}/admin/getProvidersByGroup/${groupId}`, null, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    overrideProviderPlatforms: async (brand, platforms) => {
        return await axios.post(`${config.baseURL}/admin/overrideProviderPlatforms/${brand}`, {
            defaultPlatforms: platforms
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    addBrandsToConfig: async (providerId, brands) => {
        return await axios.post(`${config.baseURL}/admin/addBrandsToConfig/${providerId}`, {
            brands
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
}
