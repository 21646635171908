const axios = require("axios");
axios.default.withCredentials = true;

const config = {};

let checkIfInitialized = () => {
    if (!config || !config.baseURL) {
        throw Error("SpotlightDataProvider was not intialized with a baseURL string.");
    }
    return true;
}

module.exports = {
    init: baseURL => {
        if (baseURL) {
            config.baseURL = baseURL;
            console.log(`config.baseURL: ${baseURL}`);
        } else {
            console.error("BaseURL not passed to spotlight data provider initialization");
        }

        return config.baseURL && true;
    },

    getAll: async () => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/metadata/all`, {}).then(response => {
            return response.data;
        }).catch(error => {
            console.error(`(SpotlightDataProvider.all) getAll: ${error.toString()}`);
            return {error};
        });
    },

    get: async appId => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/metadata/get`, {
            appId
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.error(`(SpotlightDataProvider.get) error: ${error.toString()}`);
            return {error};
        });
    },

    update: async (appId, metadata) => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/metadata/set`, {
            appId,
            metadata
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.error(`(SpotlightDataProvider.update) Error: ${error.toString()}`);
            return {error};
        });
    }
}
