const axios = require("axios");

const config = {};

module.exports = {
    init: context => {
        if (context.hasOwnProperty("baseURL")) {
            config.baseURL = context.baseURL;
        }
    },

    getMetadata: async () => {
        return await axios.post(`${config.baseURL}/admin/getMetadata`, {}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    setMetadata: async (key, value) => {
        return await axios.post(`${config.baseURL}/admin/setMetadata`, {
            key,
            value
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    testSchema: async (schema, payload) => {
        return await axios.post(`${config.baseURL}/admin/testSchema`, {
            schema,
            payload
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};
