const axios = require("axios");

const config = {};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getPermissions: async () => {
        return await axios.get(`${config.baseURL}/admin/getAllPermissions`, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getUsers: async () => {
        return await axios.get(`${config.baseURL}/admin/listUsers`, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getUserPermissions: async (userId) => {
        return await axios.get(`${config.baseURL}/admin/listPermissions/${userId}`, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    updateUserPermissions: async (userId, authz) => {
        return await axios.post(`${config.baseURL}/admin/updateUserAuthz/${userId}/${authz.toString()}`, {},
            {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getUserSessions: async userId => {
        return await axios.get(`${config.baseURL}/admin/getSessions/${userId}`, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    deleteUserSession: async sessionId => {
        return await axios.get(`${config.baseURL}/admin/deleteSession/${sessionId}`, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};
